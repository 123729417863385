import * as React from 'react';
import styled from '@emotion/styled';
import dynamic from 'next/dynamic';
import { useMediaQuery } from '@mui/material';
import { useAppContext } from '../../contexts/AppContext';
import { useTheme } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import MenuIcon from '@mui/icons-material/Menu';
import { Link } from '../Link';
import { NavigationLayoutEnum, DrawerEnum } from '../../types/enum';
import Image, { ImageLoaderProps } from 'next/image';
import useResizeObserver from '../../utils/useResizeObserver';
import useDrawer from '../../utils/useDrawer';
import { ToolbarRightContainer } from '../NavigationbarCommon/toolbarRightContainer/ToolbarRightContainer';
import { DrawerWrapper } from '../NavigationbarCommon/drawer';
import { WithNavigationbar } from '../NavigationBar/NavigationBar';

const NavigationMenu = dynamic<any>(() => import('./NavigationMenu').then((mod) => mod.NavigationMenu), {
  ssr: false,
});

const ToolbarLeft = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'flex-start',
  alignSelf: 'center',
  height: '56px',
}));

const ToolbarCenter = styled('div')(({ theme }) => ({
  flex: 1,
  display: 'flex',
  justifyContent: 'flex-start',
  alignSelf: 'stretch',
  padding: theme.spacing(0, 2),
}));

const ToolbarRight = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'flex-end',
  alignSelf: 'center',
}));

const ButtonLogo = styled(Button)(({ theme }) => ({
  width: theme.theme_component_navigation_bar_logo_width || '150px',
})) as typeof Button;

const DrawerIconButton = styled(IconButton)(({ theme }) => ({
  marginRight: theme.spacing(2),
  [theme.breakpoints.up('lg')]: {
    display: 'none',
  },
})) as typeof IconButton;

const DrawerIcon = styled(MenuIcon)(({ theme }) => ({
  color: theme.theme_component_navigation_bar_icon_color || theme.palette.primary.contrastText,
}));

const NavigationMenuContainer = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'center',
  padding: 0,
  margin: 'auto',
  height: '100%',
}));

export const NavigationBar = () => {
  const theme = useTheme();
  const appContext = useAppContext();
  const { url, componentOptions, assetUrl, headerLogoId } = appContext.useConfig();

  const layout = componentOptions.component_navigation_bar_layout || NavigationLayoutEnum.DEFAULT;
  const drawerSearchAnchor: DrawerEnum =
    componentOptions.component_navigation_bar_search_drawer_anchor || DrawerEnum.RIGHT;
  const drawerAnchor: DrawerEnum = componentOptions.component_navigation_bar_drawer_anchor || DrawerEnum.LEFT;

  const drawerDisabled = componentOptions.component_navigation_bar_drawer_disabled || false;

  const [menuSliceAmount, setMenuSliceAmount] = React.useState<number>(7);

  const sm = useMediaQuery(theme.breakpoints.up('sm'));
  const lg = useMediaQuery(theme.breakpoints.up('lg'));

  const toolBarLeftRef = React.useRef<HTMLDivElement>(null);
  const toolBarCenterRef = React.useRef<HTMLDivElement>(null);
  const toolBarRightRef = React.useRef<HTMLDivElement>(null);

  const refs = [toolBarLeftRef, toolBarCenterRef, toolBarRightRef];
  useResizeObserver(refs, setMenuSliceAmount, theme);
  
  const {
    drawer,
    setDrawer,
    drawerSearch,
    setDrawerSearch,
    handleToggle,
    handleSearchToggle,
    handleClose
  } = useDrawer(drawerAnchor, drawerSearchAnchor);

  return (
    <>
      {!drawerDisabled && 
        <DrawerWrapper
          drawer={drawer}
          handleToggle={handleToggle}
          drawerSearch={drawerSearch}
          handleSearchToggle={handleSearchToggle}
        >
          <NavigationMenu />
        </DrawerWrapper>
      }
      <Container maxWidth={false}>
        <Toolbar disableGutters>
          <ToolbarLeft ref={toolBarLeftRef}>
          {!componentOptions.component_header_menu_hidden && <DrawerIconButton
              edge="start"
              size={sm ? 'medium' : 'small'}
              onClick={() => setDrawer({ ...drawer, [drawerAnchor]: true })}
              aria-label="menu"
            >
              <DrawerIcon />
            </DrawerIconButton>}
            <ButtonLogo component={Link} href="/" title={url} aria-label="Home">
              <Image
                loader={({ src }: ImageLoaderProps) => src}
                src={`${assetUrl}${headerLogoId}/`}
                unoptimized
                alt={url}
                layout="fill"
                objectFit="contain"
              />
            </ButtonLogo>
          </ToolbarLeft>
          {!componentOptions.component_header_menu_hidden && <ToolbarCenter ref={toolBarCenterRef}>
            {layout === NavigationLayoutEnum.DEFAULT && lg && (
              <NavigationMenuContainer>
                <NavigationMenu sliceAmount={menuSliceAmount} />
              </NavigationMenuContainer>
            )}
          </ToolbarCenter>}
          <ToolbarRight ref={toolBarRightRef}>
            <ToolbarRightContainer
              setDrawerSearch={setDrawerSearch}
              handleClose={handleClose}
              drawerSearch={drawerSearch}
              drawerSearchAnchor={drawerSearchAnchor}
            />
          </ToolbarRight>
        </Toolbar>
      </Container>;
    </>
  );
};

const NavigationBarB = WithNavigationbar(NavigationBar);

export { NavigationBarB };